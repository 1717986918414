import React, { Component } from 'react'

export default class Guide extends Component {
    
    render() {
        return (
            <div className="container container-markdown">
                <h1>Guest Information</h1>
                <h2 id="welcome">Welcome</h2>
                <p>Hello and welcome to Poppy&#39;s Nook.</p>
                <p>This is a collection of useful information for the day to day enjoyment, maintenance and running of the cottage. We really hope you have a wonderful stay and the information provided here helps.</p>
                <p>We would please ask that your read the <strong>Your Holiday Home</strong> and <strong>Important Information</strong> sections upon arrival.</p>
                <p>This guide is updated frequently as needed, the latest version is available at:</p>
                <p><a href="http://poppysnook.com/guest-information">http://poppysnook.com/guest-information</a></p>
                <h2 id="guest-services">Guest Services</h2>
                <p>If you require any help with your booking, please contact the booking and management team below:</p>
                <p>Travel Chapter<br />Holly Popham<br /><a href="mailto:holly.popham@travelchapter.com">holly.popham@travelchapter.com</a><br /><a href="+441539767048">01539 767 048</a>  </p>
                <p><strong>Please Note:</strong> As Poppy&#39;s Nook is very recently opened, we are working on a more comprenshive guide, we hope this has the basics covered but in the meantime if there is anything missing or you need further information please reach out to us:</p>
                <p><a href="mailto:stay@poppysnook.com">stay@poppysnook.com</a><br /><a href="tel:+447595453949">+447595453949</a></p>
                <h2 id="your-holiday-home">Your Holiday Home</h2>
                <h3 id="wi-fi">Wi-Fi</h3>
                <p>The cottage benefits from Broadband Internet.
                If you need it, the Wi-Fi router is located in the pantry under the stairs. </p>
                <p>Use the following details to connect your devices to the network:<br /><strong>Network Name:</strong> TNCAP6C60DB<br /><strong>Wireless Key (Password):</strong> 712F4F39A4 </p>
                <h3 id="kitchen">Kitchen</h3>
                <h4 id="cooking">Cooking</h4>
                <p>The kitchen is equipped with an oven, induction hob, microwave, kettle and toaster.</p>
                <p>All cups, plates and bowls are stored in the shelving and plate racks on the kitchen walls.</p>
                <p>All pots and pans are stored in the pantry, under the stairs.</p>
                <p><strong>IMPORTANT</strong><br />Please do not put any hot pans, dishes or drinks directly onto the kitchen worktops as it will immediately damage the wood. There are metal pan stands, glass boards for hot drinks, coasters and boards provided. Thank you!</p>
                <h4 id="appliances">Appliances</h4>
                <p>The kitchen is fitted with new, modern, appliances integrated beneath the worktops: Dishwasher, Oven, Washing Machine, Fridge/Freezer.</p>
                <p>There is also a Microwave, Kettle &amp; Toaster.</p>
                <p>The appliances have been selected both for their quality and easy of use, should you have any problems using them the instructions for each are stored in a draw below the TV in the lounge.</p>
                <h4 id="quick-start-guides">Quick Start Guides</h4>
                <p>This is an early version of the guide and more detailed quick starts for each of the appliances are to follow. In the meantime, if you need any additional help getting started, get in touch and we help as best we can.</p>
                <h4 id="storage">Storage</h4>
                <p>Where to store your food and cooking items:  </p>
                <ol>
                <li>The top shelfs of the cupboards below the work tops at the back of the kitchen, first from the wall with the radiator on.</li>
                <li>The table in the pantry below the stairs.</li>
                <li>The fridge, please note the additional panel at the bottom of the fridge can be lifted for additional space.</li>
                <li>The freezer, in the fridge, at the top.</li>
                </ol>
                <h4 id="cleaning">Cleaning</h4>
                <p>There should be a small amount of dishwasher tablets, washing powder and washing up liquid stored under the sink to get you up and running on your arrival.</p>
                <p>Additional cleaning products, carpet stain remover, sponges, bin bags etc should also be available under the sink.</p>
                <p>The main bin for the cottage is located in the back lane, to the rear of the property. It will be a large black wheelie bin with the No. 4 on it. Often these bins are left at the top of the lane if you cannot immediately see it.</p>
                <p>The bins are collected on a Monday, every two weeks. The bin will need to be wheeled to the top of the lane at the back of the cottage before the collection day, we would appreciate if guests could do this so the days are not missed, keeping the bins free for other guests too.
                The scheduled dates can be found at the link below:</p>
                <p><a href="https://www.southlakeland.gov.uk/bins-and-recycling/collection-calendar/">https://www.southlakeland.gov.uk/bins-and-recycling/collection-calendar/</a></p>
                <h3 id="bathroom">Bathroom</h3>
                <p>The bathroom has a lovely walk in shower and roll top bath for you to unwind in.</p>
                <p>To use the shower there is a tap and two levers. The closest to you is the tap, that adjusts the temperature. The lower lever, closest to the tap you can turn to start/stop the water flow. The higher, slightly smaller, lever will switch output to be either the overhead rain shower or the handheld, more controlled, shower head.</p>
                <h3 id="back-yard">Back Yard</h3>
                <p>The back yard is accessible from the lane at rear of the cottage or from the french doors in the kitchen, from inside the cottage. There should be a key in the left french door for you to unlock them.</p>
                <p>The heater in the back yard is there for you to enjoy relaxing outside, on cooler days and nights. The heat lamp is on a timer, so once you press the switch outside to turn it on, it will automatically turn itself off after about 5 minutes - simply press the switch to turn it on again.</p>
                <p>If for some reason the heat lamp outside is not working, check the isolation switch for it is turned on. This is located next to the main fuse board in the pantry.</p>
                <p>There is a wooden panel up at the top of the wall, facing you, as you enter the pantry. Turn the catch on the bottom and slide the front wooden panel down.</p>
                <h3 id="security">Security</h3>
                <p>When leaving the property unattended please always check everything is safe and secure, at a minimum please remember to:</p>
                <ol>
                <li>Check there are no taps running.</li>
                <li>Turn the heating off (or down to 15) if you are not returning.</li>
                <li>Make sure the log burner door is safe, closed and the air flow in the off position (screwed in, not out), to starve any fire that might be burning.</li>
                <li>All windows are closed top and bottom, latches turned to the lock position (left).</li>
                <li>French doors are closed and locked.</li>
                </ol>
                <h3 id="log-burner">Log Burner</h3>
                <p>The log burner in the lounge is a highly regarded, safe and easy to use model supplied by Clearview Stoves. Please follow these instructions closely when using the stove.</p>
                <h4 id="starting-a-fire">Starting A Fire</h4>
                <p>There are many ways to start a fire, you usually can&#39;t go wrong with adding some kindling or logs to the stove, then adding and lighting a couple of fire lighters.</p>
                <p>Once the fire is lit close the stove door unless you need to add more wood, wood, especially wet wood can spit embers into the room, damaging the carpet and/or causing a fire.</p>
                <h4 id="managing-a-fire">Managing A Fire</h4>
                <p>Use gloves, the stove levers and controls can all get very hot.</p>
                <p>Once your fire lighters are lit, turn the wheel on the front of the stove so it comes outwards and lets more air in, this will increase the intensity of the fire and help to get it started, once started, turn the wheel back in again, but not quite all the way.</p>
                <p>The lever on the front, centre of the fire should always be pulled out, this ensures air enters the stove to wash the smoke off the glass and keep it clean.</p>
                <p>The lever on the front, off to the left hand side is used to rotate the grill under the logs burning in the fire.</p>
                <h4 id="finishing-a-fire">Finishing A Fire</h4>
                <p>When you are finished using the stove and there is still fire or hot embers, the fire needs to be starved. Follow these three steps, remember to use gloves as the stove will be hot:</p>
                <ol>
                <li>Ensure the door is closed and locked.</li>
                <li>Push the front, centre, lever all the way in.</li>
                <li>Make sure the airflow wheel at the front, centre is turned to the closed position, tight.</li>
                </ol>
                <p>Do not empty the ash tray while the stove is in use or while there are hot ashes. Once safe, old (cold) ash can be poured into the ash bucket and taken to the bin outside in the back lane.</p>
                <p><strong>IMPORTANT</strong></p>
                <p><strong>Always ensure the door is closed when the stove is burning or has hot embers in - and try to never leave it unattended.</strong>  </p>
                <p><strong>Never put anything on top of the stove, whether it is hot or cold.</strong></p>
                <h4 id="logs">Logs</h4>
                <p>You will be provided with one bag of logs for your stay, any additional logs will need to be purchased in the local area. Petrol stations and most convenience stores in the lakes have logs available all year round.</p>
                <h3 id="accidental-damage-breakages">Accidental Damage &amp; Breakages</h3>
                <p>Accidents happen, we would appreciate it if you could let us know if you have accidentally broken or damaged anything at the cottage during your stay.</p>
                <p>There is an inventory and photos of everything, including furniture, that is checked after every guest leaves ensuring the next guests get to enjoy a great stay too - but some times we might miss the little things.</p>
                <p>Please let us know, however big or small, it would be greatly appreciated and we don&#39;t bite.</p>
                <p>Thank you!</p>
                <h3 id="gas-electrics-plumbing-heating">Gas, Electrics, Plumbing &amp; Heating</h3>
                <p>If there is a failure with any of the appliances or utilities at the house, please contact the repair team below:</p>
                <p>Orian Property Services (South)<br />Unit 6, Alnat Business Park,<br />Lindale, Cumbria. LA11 6PQ<br /><a href="+441539536956">01539 536 956</a></p>
                <h3 id="general-maintenance">General Maintenance</h3>
                <p>If there is a failure or problem that requires general maintenance, inside or outside of the property, please contact the maintenance team:</p>
                <p>Orian Property Services (South)<br />Unit 6, Alnat Business Park,<br />Lindale, Cumbria. LA11 6PQ<br /><a href="+441539536956">01539 536 956</a></p>
                <h2 id="important-information">Important information</h2>
                <h3 id="gas-central-heating">Gas Central Heating</h3>
                <p>Central heating and hot water is provided to the property via a gas powered combi boiler and controlled with a Nest thermostat.</p>
                <p><strong>Thermostat</strong><br />You can adjust the central heating with the Nest thermostat located on the landing at the top of the stairs, outside the single bedroom.</p>
                <p><strong>Boiler</strong><br />The boiler is located in the pine cupboard, mounted on the wall in the corner of the dressing room, upstairs.</p>
                <p><strong>Gas Main</strong><br />The tap to turn off the gas is located behind a cupboard in the kitchen. Facing the rear wall of the kitchen, with the radiator on - the first cupboard underneath the worktop on your right, on the opposite side to the sink. On the lowest shelf, the rear panel is labeled with &#39;Gas&#39;, two screws hold the panel in place and behind there is the tap to turn off the gas. A screw driver can be found in the kitchen drawers, below the cutlery draw if one is not present on the hook.</p>
                <p><strong>Gas Meter</strong><br />The gas meter is located behind a cupboard in the kitchen. Facing the rear wall of the kitchen, with the radiator on - the first cupboard underneath the worktop on your left, beside the sink. On the lowest shelf, there is a boxed in panel labeled with &#39;Gas Meter&#39; and handle it to pull the box out and reveal it.</p>
                <p><strong>Carbon Monoxide Detector</strong><br />The carbon monoxide detector is located on the landing at the top of the stairs, at shoulder height, outside of the dressing room where the boiler is located.</p>
                <h3 id="fire-safety">Fire Safety</h3>
                <p><strong>Fire Blanket &amp; Extinguisher</strong><br />The fire blanket and extinguisher are located in the kitchen, below the worktop in the open space immediately to the right of the door as you walk into the kitchen from the lounge.</p>
                <p><strong>Smoke Alarms</strong><br />Mains powered smoke alarms are fitted in two locations at the house, on the ceiling at the bottom of the stairs and on the ceiling above the landing at the top of the stairs.</p>
                <h3 id="electrics">Electrics</h3>
                <p>The main fuse board for the electrics is located in the panty under the stairs. There is a wooden panel up at the top of the wall, facing you, as you enter the pantry. Turn the catch on the bottom and slide the front wooden panel down.</p>
                <h3 id="water">Water</h3>
                <p>The stop tap for the water supply to the house is located under the kitchen sink. There is a run of blue pipe with a black tap on top.</p>
                <h3 id="kitchen-appliances">Kitchen Appliances</h3>
                <p>All of the isolation switches for the kitchen appliances are located in the cupboard beneath the worktop to the right of the kitchen sink.</p>
                <h3 id="outdoor-heater">Outdoor Heater</h3>
                <p>The outdoor heater isolation switch is located next to the main fuse board in the pantry under the stairs. There is a wooden panel up at the top of the wall, facing you, as you enter the pantry. Turn the catch on the bottom and slide the front wooden panel down.</p>
                <h2 id="useful-information">Useful Information</h2>
                <h3 id="getting-around">Getting Around</h3>
                <h4 id="trains">Trains</h4>
                <p>Grange-Over-Sands has it&#39;s own train station down on the seafront, near the duck pond. Trains from here will link you to the rest of the lakes and beyond.</p>
                <p><strong>Grange-Over-Sands Train Station</strong><br />Lindale Road, Grange-over-Sands LA11 6EH</p>
                <h4 id="buses">Buses</h4>
                <p>The nearest bus stops to the cottage are less than a hundred yards up and down each side of the road.
                The majourity of buses that pass through also go through the train station down in the village.</p>
                <p><strong>Buses to and through Grange-over-Sands:</strong><br />X6  Barrow - Grange - Kendal<br />530 Kendal - Grange - Cartmel<br />532 Grange - Kents Bank - Flookburgh - Cark - Cartmel  </p>
                <h4 id="taxis">Taxis</h4>
                <p>There are usually plenty of taxi services available in the local area however the numbers and services tend to change frequently. A few are listed below, you might need to ring a few different ones.</p>
                <p><strong>Trevor Gills</strong><br /><a href="+447968341460">07968 341 460</a></p>
                <p><strong>C Cabs</strong><br /><a href="+447976556963">07976 556 963</a></p>
                <p><strong>Estate Cars</strong><br /><a href="+447974111543">07974 111 543</a></p>
                <p><strong>Economical</strong><br /><a href="+441539558901">01539 558 901</a></p>
                <p><strong>A Star</strong><br /><a href="+447805862658">07805 862 658</a></p>
                <h4 id="walking-distance">Walking Distance</h4>
                <p>A short walk down the hill will get you into the village of Grange-Over-Sands. It is spread out some what in two parts, the first at the bottom of the hill from the cottage. The second part is further on towards the train station and duck pond. You will find plenty of local independent shops, cafes etc.</p>
                <p>A longer walk up the hill will take you into Cartmel. Cartmel is only 1.6 miles away, about a 30 minute walk (or a 5 minute drive). You can stick to the roads or walk the more scenic route over Cartmel Fell with all the wonderful views of the bay and surrounding area.</p>
                <h4 id="nearest-town">Nearest Town</h4>
                <p>The nearest town with big supermarkets, modern outlets and banks etc is Kendal. Kendal is a 25 minute drive (14 miles), head east out of the village to the A590, signage along the dual carriage way will direct you there.</p>
                <h3 id="medical">Medical</h3>
                <p>The nearest medical services to the cottage, most are within reasonable walking distance however anywhere outside of Grange-Over-Sands will require transportation (eg. Hospital).</p>
                <p><strong>Hospital - Westmorland General</strong><br /><em>Burton Rd, Kendal LA9 7RG</em><br /><a href="tel:+441539732288">01539 732 288</a></p>
                <p><strong>Doctors &amp; Pharmacy - Nutwood Medical Practice</strong><br />The Health Centre, Kents Bank Road, Grange-over-Sands LA11 7DJ<br /><a href="tel:+441539715500">01539 715 500</a></p>
                <p><strong>Pharmacy - Well</strong><br /><em>Kents Bank Rd, Grange-over-Sands LA11 7EY</em><br /><a href="tel:+441539532024">01539 532 024</a></p>
                <p><strong>Dentist - Avondale Dental Practice</strong><br /><em>Kents Bank Rd, Grange-over-Sands LA11 7EY</em>  </p>
                <p><strong>Vets - Archway Veterinary Practice</strong><br /><em>Station Square, Lindale Rd, Grange-over-Sands LA11 6EH</em><br /><a href="+441539532669">015395 32669</a></p>
                <h3 id="local-shops-cafes-restaurants">Local Shops, Cafes, Restaurants</h3>
                <p>A brief list of what the area has to offer close by but you will find much more on your travels too.</p>
                <h5 id="grange-over-sands">Grange-Over-Sands</h5>
                <p><strong>The Country House</strong><br /><em>Main St, Grange-over-Sands LA11 6DP</em><br />A wonderful independent gift, jewellery, cards, furniture and interior shop. Some items of which you will see around the cottage as Helen who owns the business is to thank for the interior design here. </p>
                <p><strong>Choco-Lori</strong><br /><em>1 Ingwell house, Main Street, Grange-over-Sands LA11 6DP</em><br />A unique, independent, hand made chocolate shop and cafe, a must visit for those with a sweet tooth.</p>
                <p><strong>Higginsons of Grange</strong><br /><em>Main St, Grange-over-Sands LA11 6AB</em><br />Outstanding butchers, expect queues out the door for their huge offering of wonderful meats, meals and pies.</p>
                <p><strong>The Hazelmere</strong><br /><em>2 Yewbarrow Terrace, Grange-over-Sands LA11 6ED</em><br />An authentic local bakery and cafe over looking the duck pond.</p>
                <p><strong>Tesco Express</strong><br /><em>Kent Bank Rd, Grange Over Sands, LA11 7EY</em><br />Modern local convience store, with all the essentials.</p>
                <p><strong>Co-op</strong><br /><em>Kent Bank Rd, Grange Over Sands, LA11 7EY</em><br />Modern local convience store, with all the essentials.</p>
                <p><strong>The Estuary</strong><br /><em>Main St, Grange-over-Sands LA11 6DY</em><br />A stylish bistro serving food and drinks all day.</p>
                <h4 id="cartmel">Cartmel</h4>
                <p><strong>Rogans &amp; Co</strong><br /><em>Devonshire Square, Cartmel, Grange-over-Sands LA11 6QD</em><br />Simon Rogan&#39;s relaxed neighbourhood restaurant in the magical village of Cartmel. Sister restaurant to the legendary L&#39;Enclume.
                (Michelin Star Restaurant)</p>
                <p><strong>L&#39;Enclume</strong><br /><em>Cavendish St, Cartmel, Grange-over-Sands LA11 6QA</em><br />Simon Rogan&#39;s iconic restaurant, where passion and creativity showcase the finest ingredients and harness the powerful connection between food and nature.
                (Michelin Star Restaurant)</p>
                <p><strong>Cartmel Drinkshop &amp; Wine Snug</strong><br /><em>Unsworths Yard, Ford Rd, Cartmel, Grange-over-Sands LA11 6PG</em><br />Cartmel Drink Shop specialises in the finest wines, spirits and liqueurs from around the world with a unique “try before you buy” available across much of the range.</p>
                <p><strong>The Cavendish Arms</strong><br /><em>Cavendish Street, Cartmel, Grange-over-Sands LA11 6QA</em><br />A great gastro pub for food and drinks. One of four in Cartmel but the most popular for food. Just off the same square you will find the other local pubs: Royal Oak Inn (shows sports), The Kings Arms, The Pig &amp; Whistle.</p>
                <p><strong>Cartmel Sticky Toffee Pudding Shop</strong><br /><em>Cartmel Village Shop, Parkgate House, 1 The Square, Cartmel LA11 6QB</em><br />The home of this famous sticky toffee pudding.</p>
                <h2 id="address">Address</h2>
                <p>The full address of the cottage for reference.</p>
                <p>Poppy&#39;s Nook<br />4 Fell Cottages<br />Grange Fell Road<br />Grange-Over-Sands<br />Cumbria<br />LA11 6AH</p>
                <h2 id="feedback">Feedback</h2>
                <p>We would love to hear your feedback, on your stay at the cottage, the local area and anything you think might need updating or adding to this guide.</p>
                <p>Send us an email:<br /><a href="mailto:stay@poppysnook.com">stay@poppysnook.com</a></p>
                <p>Thank you!</p>

            </div>
        )
    }
}