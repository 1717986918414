import React from 'react';
import CottageFront from './CottageFront.jpg';

export default function Home() {
    return (
        <>
            <div>
            <div className="container">
                    <div className="hero">
                        <h2>A beautiful self catering holiday cottage in The English Lake District.</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="container-skin">
                        <p>Browse the availability calendar, search specific dates and explore prices.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="container-skin">
                        <div className="booking">
                        <a href="https://poppysnook.staydirectly.com/" className="book">BOOK</a>
                        </div>
                    </div>
                </div>

                <div className="container">
                <div className="container-skin">
                    <p>
                        <img src={CottageFront} alt="" width="320" height="426" />
                    </p>
                </div>
                </div>

                <div className="container">
                    <div className="container-skin">
                    <div className="location">
                        <h3>Location</h3>
                        <p>
                            Poppy's Nook
                            4 Grange Fell Road<br/>
                            Grange-Over-Sands<br/>
                            Cumbria<br/>
                            LA11 6AH
                        </p>
                    </div>
                    </div>
                </div>

                <div className="container">
                    <div className="container-skin">
                    <div className="location">
                        <h3>Get in touch</h3>
                        <p><a href="mailto:stay@poppysnook.com" className="stay">stay@poppysnook.com</a></p>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
  }