import React from 'react';
import { Outlet } from "react-router-dom";
import '../App.css';

export default function Root() {
    return (
        <div className="App">
            <div className="main">
                <div className="bar"></div>
                <div className="container">
                    <div className="header">
                        <h1>Poppy's Nook</h1>
                    </div>
                </div>
                <div>
                    <Outlet />
                </div>
            </div>
        </div>
    );
  }